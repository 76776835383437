import React from 'react'
import './Field.css'

const Field = ({FieldName,onchange,inputRef,value}) => {
  return (
    <div id='Champ'>
        <label htmlFor="Input_Champ">{FieldName}</label>
        <input type="text" name="field" id="Input_Champ" onChange={onchange} ref={inputRef} value={value}/>
    </div>
  )
}

export default Field