import React from 'react'
import './Notif.css'

const Notif = ({Message,onclick}) => {


  return (
   <div id='Notif'>

      <div>
         {Message}
      </div>

      <button onClick={onclick}>OK</button>

   </div>
  )
}

export default Notif