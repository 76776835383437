import React, { useEffect } from 'react'
import './Dashboard.css'
import Pill from '../../components/Pill/Pill'
import OrnoirMarket from '../../components/Logo/Logo'
import { onAuthStateChanged ,signOut} from 'firebase/auth'
import {auth} from '../../firebase/firebase'

import { useNavigate } from 'react-router-dom'

import Form from '../../components/Form/Form'






const Dashboard = () => {




    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
              // ...
              console.log("uid", uid)
            } else {
              // User is signed out
              // ...
              console.log("user is logged out")
            }
          });

    }, [])

    const navigate = useNavigate();

    const handleLogout = () => {               
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
        // An error happened.
        });
    }


  return (
    <main id='Dashboard'>

        <nav id="navbar">
            <OrnoirMarket/>
            <div id="Tabs">
                <Pill text={'Produits'} color={"#fff"} bgcolor={'transparent'} bordercolor={'#E8C133'}/>
                <Pill text={'Statistiques'} color={'#000'} bgcolor={'#E8C133'} bordercolor={'transparent'}/>
            </div>
            
        </nav>
        
        <div id="searchBar">
            <input type="text" name="SearchBar" id="SearchBarInput" />
            <Pill text={"rechercher"} color={'#fff'} bgcolor={'transparent'} bordercolor={"#E8C133"} />
        </div>

        <section>
            <div id='SideBar'>
               <span className='heading'>Database</span>
                <Pill text={'Database Produits'} color={"#000"} bgcolor={"#fff"} bordercolor={"transparent"} />

                <div id="connexionStatus">
                    <div id="profil"></div>
                    <Pill text={'deconnexion'} classe={"btn"} color={"#fff"} bgcolor={"transparent"} bordercolor={'#E8C133'} onclick={handleLogout}/>
                </div>
            </div>
            <div id='Main'>
                <div id="PathLocation" className='heading'>
                    Database Produits {'>'}
                </div>
                <div id="CurrentActivity">

                     <Form/>{/**/}

                
                    
                    <div id="Action">
                        <Pill text={"Ajoutez un produit"} color={"#000"} bgcolor={"#E8C133"} bordercolor={"transparent"} classe={"btn"}/>
                        <Pill text={"Voir les autres produits"} color={"#fff"} bgcolor={"transparent"} bordercolor={"#e8c133"} classe={"btn"}/>
                    </div>
                   
                </div>
            </div>
        </section>
        


    </main>
  )
}

export default Dashboard