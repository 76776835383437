import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes,Route } from 'react-router-dom';
import Connexion from './Pages/Connexion/Connexion';
import Dashboard from './Pages/Dashboard/Dashboard';
import Sandbox from './Pages/Sandbox/Sandbox';


function App() {


 

  return (
  
    <div className="App">
     
      <Router>

        <Routes>

          <Route path='/' element={<Connexion/>}/>
          <Route path='/Connexion' element={<Connexion/>}/>
          <Route path='/Dashboard' element={<Dashboard/>}/>
          <Route path='/Sandbox' element={<Sandbox/>}/>

        </Routes>
    
      </Router>
      
    </div>
  
    
  );
}

export default App;
