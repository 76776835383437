import React from 'react'
import './Pill.css'

const Pill = ({text,color,bgcolor,bordercolor,classe,onclick}) => {

  return (
    <div id='Pill' onClick={onclick} style={{'--color':color,'--bgcolor': bgcolor,'--bordercolor':bordercolor}} className={classe} >{text}</div>
  )
}

export default Pill