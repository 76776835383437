import React from 'react'
import Notif from '../../components/Notif/Notif'

const Sandbox = () => {
  return (
    <div style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
        <div>
           <Notif onclick={e=>alert('ferme')}/>
        </div>
        
    </div>
  )
}

export default Sandbox