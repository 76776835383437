import React from 'react'
import './Connexion.css'
import OrnoirMarket from '../../components/Logo/Logo'
import Logo from '../../Assets/Logo.svg'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../firebase/firebase'
import Notif from '../../components/Notif/Notif'

const Connexion = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notif,setnotif]=useState(false)
  const [ErrorMessage,setErrorMessage]=useState()

  const Connecter = (e) => {
      e.preventDefault();
      signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          navigate("/dashboard")
          //console.log(user);
          //alert(user)
          setErrorMessage("vous êtes connecté")
          setnotif(true)
      })
      .catch((error) => {
          //const errorCode = error.code;
          const errorMessage = error.message;
          //console.log(errorCode, errorMessage)
          //alert(errorCode, errorMessage)
          setErrorMessage(errorMessage)
          setnotif(true)
      });

  }



  return (
    <main className='Connexionpage'>

        {notif?<Notif Message={ErrorMessage} onclick={e=>setnotif(false)}/>:''}

        <div className='Connexionpagediv'>

            <OrnoirMarket/>

            <div className='Logo'>
                <img src={Logo} alt="Logo "/>
            </div>

            <div id='formulaire'>
                    <label htmlFor="Username">Username</label>

                    <input id='Username' name='email' required type="text"  placeholder='Username' onChange={(e)=>setEmail(e.target.value)}/>
                
                    <label htmlFor="password">Mot de Passe</label>
                    
                    <input type="password" name="password" required id="password"  placeholder='mot de passe' onChange={(e)=>setPassword(e.target.value)}/>
                    
            </div>

            <div id="buttonconnexion" onClick={Connecter}>
                Connexion
            </div>

        </div> 
        
    </main>
  )
}

export default Connexion