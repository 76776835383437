import React, { useEffect, useRef, useState } from 'react'
import Field from '../Field/Field'
import Pill from '../Pill/Pill'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase/firebase'
import Notif from '../Notif/Notif'

const Form = () => {


    const [Nom,setNom]=useState('')
    const [Categorie,setCategorie]=useState('')
    const [Categories,setCategories]=useState([])
    const [Description, setDescription] = useState('')
    const [EtatDuProduit, setEtatduProduit] = useState('')
    const [Quantite, setQuantite] = useState('')
    const [PrixVendeur, setPrix] = useState('')
    const [PrixInApp, setPrixInApp] = useState('')
    const [TauxDeMarge, setTauxDeMarge] = useState('')

    const [ImageCardUrL, setImageCardUrL] = useState('')
    const [ImageBanniereUrl, ImagesetBanniereUrl] = useState('')
    const [ImageCardOrderUrl, setImageCardOrderUrl] = useState('')


  const [notif,setnotif]=useState(false)
  const [ErrorMessage,setErrorMessage]=useState()



    const CategorieInput=useRef()

    const Produit={
        Nom:Nom,
        Categorie : Categorie ,
        Categories: Categories,
        Description :Description,
        EtatDuProduit :EtatDuProduit,
        Quantite :Quantite,
        PrixVendeur :PrixVendeur,
        PrixInApp:PrixInApp,
        TauxDeMarge :TauxDeMarge,
        createdAt: serverTimestamp(),
  
    }


    const AjouterUnProduit= async ()=>{


        await addDoc(collection(db, "Produits"), Produit).then(()=>{
        /*await addDoc(collection(db,"Test"),Produit).then(
      
            setnotif(true)
          
          ).catch(
            (error)=>{
              setErrorMessage(error)
            }
          );
*/
        
         
       
        //alert("Produit bien ajouté a la base de donnée ")
        setErrorMessage("Produit bien ajouté a la base de donnée")
        setnotif(true)

        setNom('')
        setCategorie('')
        setCategories([])
        setDescription('')
        setEtatduProduit('')
        setQuantite('')
        setPrix('')
        setPrixInApp('')
        setTauxDeMarge('')}
        
        ).catch(
          (reason)=>{
             setErrorMessage(reason.toString())
              setnotif(true)
          }
        )


    }



        /* Test de d'ajout de categorie */

    const AjouterCategorie=()=>{

      setCategories([...Categories,Categorie])
      CategorieInput.current.value=''
      //console.log(categories)
      
    }


    /*    useEffect(() => {
     console.log(Categories)
    }, [Categories])

    */

    /*useEffect(() => {
    console.log(Produit)
    }, [Nom])
    
*/

    


  return (
    <>
      {/*
        !isDone?<Notif/>:''*/
      }
    
      
      {notif?<Notif Message={ErrorMessage} onclick={e=>setnotif(false)}/>:''}
      


          <Field FieldName={'Nom du produit'} onchange={e=>setNom(e.currentTarget.value)} value={Nom}/>

        <div id='text_descr'>
            <label htmlFor="textarea"  >description</label>
            <textarea name="" id="textarea" onChange={e=>setDescription(e.currentTarget.value)} value={Description}></textarea>
        </div>


        

        <div style={{display:'flex',justifyContent:"flex-start",alignItems:"flex-start", flexWrap:'wrap',gap:'1rem',flexDirection:'column'}}>


    
            <div style={{display:'flex',justifyContent:"flex-start",alignItems:"flex-end", gap:'1rem'}}>

              
                <Field FieldName={"Catégorie"} onchange={e=>setCategorie(e.currentTarget.value)} inputRef={CategorieInput} />

                <Pill bgcolor={"#fff"} bordercolor={"transparent"} classe={"btn"} color={"#000"} text={"Ajouter une catégorie"}  onclick={AjouterCategorie}/>



            </div>


            <div style={{display:"flex", gap:'1rem'}}>
              {
                Categories.map(
                  (categorie,index)=>{
                    return (
                      <Pill bgcolor={'#000'} bordercolor={"#fff"} color={"#fff"} key={index} text={categorie} onclick={(e) => {
                        setCategories(
                          Categories.filter(elmnt =>
                           elmnt!==e.currentTarget.innerText
                          )
                        );
                      }}/>
                    )
                  }
                )
              }
            </div>


        </div>

        <div>
          <Field FieldName={'Etat du Produit'} onchange={e=>setEtatduProduit(e.currentTarget.value)} value={EtatDuProduit}/>

          <Field FieldName={'Quantité'} onchange={e=>setQuantite(e.currentTarget.value)} value={Quantite}/>

          <Field FieldName={'Prix du Vendeur'} onchange={e=>setPrix(e.currentTarget.value)} value={PrixVendeur}/>
          <Field FieldName={'Prix dans l\'App'} onchange={e=>setPrixInApp(e.currentTarget.value)} value={PrixInApp}/>
          <Field FieldName={'Taux de marge'} onchange={e=>setTauxDeMarge(e.currentTarget.value)} value={TauxDeMarge}/>

        </div>


        <div style={{display:"flex",justifyContent:"center",alignContent:"center"}}>

          <Pill bgcolor={"#e8c133"} bordercolor={"transparent"} classe={"btn"} color={"#000"} onclick={AjouterUnProduit} text={"Enregistrer le produit"}/>

        </div>
        

        

    </>
  )

 
}

export default Form