import React from 'react'

const Logo = () => {

const Style={

    Logo: {
        fontSize :'4rem'

    },

    Ornoir : { 
                color:'#E8C133',
                fontWeight:'900'
    },

    Market : {
                color:'#fff',
                fontWeight :'100'

    }
}


  return (
    <div style={Style.Logo}>

        <span style={Style.Ornoir}>Ornoir </span>
        <span style={Style.Market}>Market</span>
        
    </div>
  )
}

export default Logo